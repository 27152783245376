var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar"},[_vm._l((_vm.sidebarGroups),function(group){return _c('div',{key:group.name,staticClass:"sidebar__group"},[(group.items.length)?_c('h4',{staticClass:"sidebar__label"},[_vm._v(_vm._s(group.name))]):_vm._e(),_c('ul',{staticClass:"sidebar__list"},_vm._l((group.items),function(item){return _c('li',{key:item.id,staticClass:"sidebar__listitem"},[_c('router-link',{staticClass:"sidebar__link",class:{
            'router-link-active-parent':
              (_vm.activeItem && _vm.activeItem.department && _vm.activeItem.department.id === item.id) ||
              (_vm.activeItem && _vm.activeItem.organization && _vm.activeItem.organization.id === item.id),
          },attrs:{"to":{ name: 'ItemHome', params: { slug: item.slug } }}},[_c('em',{class:("sidebar__category-icon fas fa-fw fa-" + (group.icon))}),_vm._v(" "+_vm._s(item.name)+" "),(item.team && item.team.map(function (ref) {
                    var id = ref.id;

                    return id;
}).includes(_vm.user.email))?_c('i',{staticClass:"sidebar__user-icon fas fa-user-circle"}):_vm._e()])],1)}),0)])}),_c('div',{staticClass:"sidebar__group sidebar__bottom button-col"},[_c('theme-toggle'),(_vm.user.admin)?_c('router-link',{staticClass:"btn btn--ter btn--icon",attrs:{"to":{ name: 'Admin' }}},[_c('i',{staticClass:"icon fa fa-fw fa-cogs"}),_c('span',{staticClass:"btn--label"},[_vm._v(_vm._s(_vm.$t('general.admin')))])]):_vm._e(),_c('router-link',{staticClass:"btn btn--ter btn--icon",attrs:{"to":{ name: 'Help' }}},[_c('i',{staticClass:"icon fa fa-fw fa-question-circle"}),_c('span',{staticClass:"btn--label"},[_vm._v(_vm._s(_vm.$t('general.help')))])]),_c('button',{staticClass:"btn btn--ter btn--icon",on:{"click":_vm.signOut}},[_c('i',{staticClass:"icon fa fa-fw fa-sign-out-alt"}),_c('span',{staticClass:"btn--label"},[_vm._v(_vm._s(_vm.$t('general.signOut')))])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }